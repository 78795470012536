<script setup lang="ts">
import { KsButton } from "@aschehoug/kloss";

definePageMeta({
  middleware: ["check-auth"],
});

const route = useRoute();

const login = useLogin(() => route.query.redirectTo?.toString());

const { public: env } = useRuntimeConfig();

useHead({
  bodyAttrs: {
    class: "bg-beige-10",
  },
});
</script>

<template>
  <div>
    <LayoutBreadCrumbs
      :text-conversions="{ login: 'Logg inn' }"
      :background="true"
    />
    <main id="main-content">
      <section>
        <h1 class="heading1">
          Logg inn
        </h1>
        <p>
          Ved å logge inn kan du kjøpe læremidler og få oversikt over tidligere
          kjøp. Du kan også se hvilke læremidler du har tilgang til.
        </p>
        <section class="button-group">
          <KsButton
            class="feide-button"
            variant="primary"
            shape="rounded"
            @click="login('feide')"
          >
            Logg inn med Feide
          </KsButton>
          <vipps-mobilepay-button
            type="button"
            brand="vipps"
            language="no"
            variant="primary"
            :rounded="true"
            verb="login"
            :stretched="false"
            :branded="true"
            :loading="false"
            @click="login('vipps')"
          />
        </section>
      </section>
      <img
        class="figures"
        :src="`${env.assetPrefix}/images/characters-login.png`"
        alt=""
      />
    </main>
  </div>
</template>

<style scoped lang="scss">
#main-content {
  display: flex;
  width: min(95vw, theme("spacing.content"));
  margin-inline: auto;
  justify-content: flex-start;
  gap: 10rem;

  section {
    display: flex;
    flex-direction: column;
  }

  > section {
    margin-block: 5rem;
    color: theme("colors.green.50");
    width: min(90vw, 100%);
    gap: 2rem;

    @media screen and (min-width: $small) {
      width: 50%;
    }
  }

  h1 {
    font-weight: 400;
    line-height: 116.667%;
  }

  p {
    font-size: 1.5rem;
    line-height: 133.333%;
  }

  .feide-button {
    // In order to match the Vipps button
    font-size: 18.5px;
  }

  .button-group {
    gap: 1.25rem;
    align-items: flex-start;
  }

  .figures {
    display: none;
    object-fit: contain;
    width: 30%;

    @media screen and (min-width: $small) {
      display: block;
    }
  }
}
</style>
